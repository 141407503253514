<template>
  <div>
    <b-container fluid>
    <b-row>
      <b-col>
        <iq-card>
          <template v-slot:body>
            <slot v-if="dataThere">
              <h4>
                <span v-if="profileStatus === 4">
                  {{ $i18n.locale === 'en' ? 'Please, complete your' : 'অনুগ্রহ করে আপনার'}}
                  <router-link :to="{ path: '/trainee-trainer-panel/profile' }" class="link-primary" style="color: #7d2bd9">
                  {{ $i18n.locale === 'en' ? 'profile' : 'প্রোফাইল' }}
                  </router-link>
                  {{ $i18n.locale === 'en' ? ' and wait for admin approval.': 'সম্পূর্ণ করে নিবন্ধনের জন্য চূড়ান্ত সংরক্ষণ করুন এবং অনুমোদনের জন্য অপেক্ষা করুন' }}
                </span>
                <span v-if="profileStatus === 1">
                  {{ $i18n.locale === 'en' ? 'Your profile is pending approval by admin. Once your profile is approved, you will be notified via a confirmation message.': 'আপনার প্রোফালটি এডমিন এর কাছে অনুমোদনের অপেক্ষায় আছে। আপনার প্রোফালটি অনুমোদন হয়ে গেলে আপনাকে নিশ্চিতকরণ Message এর মাধ্যমে জানানো হবে।' }}
                </span>
                <span v-if="profileStatus === 2">
                  {{ $t('externalPanel.profile_rejected_msg') }}
                </span>
                <span v-if="profileStatus === 3">
                  <!-- {{ $t('externalPanel.approval_msg') }} -->
                  {{ $i18n.locale === 'en' ? 'Once your profile is approved, apply for the training for which you have received nomination from the list of nomination notification.' : 'আপনার প্রোফালটি অনুমোদন হয়েছে, নমিনেশন বিজ্ঞপ্তির তালিকা থেকে আপনি যে ট্রেনিং এর জন্য নমিনেশন পেয়েছেন সেই ট্রেনিং জন্য আবেদন করতে হবে।' }}
                </span>
              </h4>
              <br/>
            </slot>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    </b-container>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'

export default {
  data () {
    return {
      loadingState: false,
      overlay: true,
      user: {},
      profileStatus: 0,
      approveStatus: '',
      dataThere: false,
      series: [{
        name: 'Total',
        data: [45, 70, 49, 60]
      }],
      search: {
        warehouse_id: '',
        farmer_id: ''
      },
      approvalMessage: false,
      trainingType: this.$store.state.ExternalUserIrrigation.panelObj.trainingType,
      productStockSeriesData: [30, 70],
      deliveryScheduleList: [],
      showData: false,
      showHeading: false
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser',
      panelObj: 'ExternalUserIrrigation/panelObj'
    }),
    authUser () {
       return this.$store.state.Auth.authUser
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.checkTrainingUser()
  },
  methods: {
    checkTrainingUser () {
      this.approvalMessage = false
      const appliedOrg = {
        applied_org_id: this.$store.state.ExternalUserIrrigation.panelObj.trainingOrg,
        applied_type: this.$store.state.ExternalUserIrrigation.panelObj.applied_type
      }
      RestApi.getData(trainingElearningServiceBaseUrl, 'personal-infos/check-user', appliedOrg).then(response => {
        if (response.success) {
            this.profile = response.data
          if (parseInt(this.trainingType) === 2) {
            this.profileStatus = response.data.status
          } else if (parseInt(this.trainingType) === 1) {
            this.profileStatus = response.data.trainer_status
          }
          this.dataThere = true
        } else {
          this.dataThere = true
          this.profileStatus = 4
          this.$router.push('/trainee-trainer-panel/profile')
        }
      })
    }
  }
}
</script>

<style scoped>
.min-height {
  height: 100vh;
}
.my-card {
  min-height: 100vh;
  background-image: url("../../../../assets/images/login/bg1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.t-shadow{
  text-shadow: 1px 1px 1px #000;
}
</style>
